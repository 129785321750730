import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ApiService } from '../app/api.service';
import { apiEndPoint } from '../app/apiEndPoint.service';
import { AuthService } from '../app/auth/service/auth.service';
import { catchError } from 'rxjs/operators';

//declare var $: any;
// var that = this;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'cvbuild';

  constructor(
    private apiService: ApiService,
    private _authService: AuthService,
  ){ }

  ngOnInit() {
    // this.getProfile();
  }

  // getProfile()
  // {
  //   this.apiService.getMethodApiRequest(apiEndPoint.MY_PROFILE).pipe(catchError(this.apiService.handleError)).subscribe(
  //     (profileData: any) => {
  //       this._authService.storeIsPaid(profileData.paidStatus);
  //     },
  //     (error: any) => {
  //       console.log("error", error);
  //     }
  //   );
  // }
}
