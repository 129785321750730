//modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { PageNotFoundComponent } from './landings/page-not-found/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { GoogleAnalyticsComponent } from './google-analytics/google-analytics.component';

// components
const routes: Routes = [
  { path : 'googleAnalytics',component :GoogleAnalyticsComponent},
  { path: 'landings',loadChildren: () => import('./landings/landings.module').then(m => m.LandingsModule) },
  { path: 'dashboard'  , loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: '', redirectTo: 'landings', pathMatch: 'full' },
  { path: 'logout', component :  LogoutComponent},
  { path: '**', component: PageNotFoundComponent },
  { path : 'error-page',component :ErrorComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule ]
})
export class AppRoutingModule {}
