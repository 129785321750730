import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Common {

    public apiUrl  = "public/api";
    public BEARER_TOKEN  = "1|TzdZ7kDMT0N6WK3sVN1WBrRBEsCjs7OsagsVVwAf";
    public MY_BETA_CONTENT_LIST = this.apiUrl+'/users/list-mycontent';

}