// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
//  apiUrl  : "https://cvbuilder.colanapps.in/public/api",
apiUrl  : "https://admin.roozgaar.ai/api", 
baseUrl : "https://admin.roozgaar.ai",
//  apiUrl    : "http://127.0.0.1:8000/api",  
//  baseUrl : "http://127.0.0.1:8000",


  BEARER_TOKEN  : localStorage.getItem('authToken'),
  // MY_BETA_CONTENT_LIST : "https://cvbuilder.colanapps.in/public/api/users/list-mycontent",
  // SECTION_CONTENT : "https://cvbuilder.colanapps.in/public/api/users/list-section-content",
  // //FIELD_LIST : "https://cvbuilder.colanapps.in/public/api/users/field-list",
  // MY_BETA_CONTENT_SECTION  : "https://cvbuilder.colanapps.in/public/api/users/relation-content",
  
  MY_BETA_CONTENT_LIST : "https://admin.roozgaar.ai/api/users/list-mycontent",
  SECTION_CONTENT : "https://admin.roozgaar.ai/api/users/list-section-content",
  //FIELD_LIST : "https://cvbuilder.colanapps.in/public/api/users/field-list",
  MY_BETA_CONTENT_SECTION  : "https://admin.roozgaar.ai/api/users/relation-content",
  bearerToken: localStorage.getItem('authToken'),
};
 
export const apiEndPoint = {
  LOGIN                             : `${environment.apiUrl}/users/login`,
  REGISTER                          : `${environment.apiUrl}/users/register`,
  PASSWORD_CHANGE_REQUEST           : `${environment.apiUrl}/users/forget-password`,
  PASSWORD_UPDATE                   : `${environment.apiUrl}/users/update-forget-password`,
  VERIFICATION                      : `${environment.apiUrl}/users/otp-verification`,
  RESENDCODE                        : `${environment.apiUrl}/users/resend-otp`,
  EXPERIENCELEVEL                   : `${environment.apiUrl}/users/get-experiencel-level`,
  COUNTRY                           : `${environment.apiUrl}/users/country-list`,
  ROLES                             : `${environment.apiUrl}/all-roles`,
  MYPROFILE                         : `${environment.apiUrl}/users/account-detail`,
  SAVEMYPROFILE                     : `${environment.apiUrl}/users/user-account-change`,
  EDITEMAIL                         : `${environment.apiUrl}/users/user-email-edit`,
  CHANGEPROFILEIMAGE                : `${environment.apiUrl}/users/update-profile-picture`,
  CHANGEPASSWORD                    : `${environment.apiUrl}/users/user-password-change`,
  DELETEPROFILEIMAGE                : `${environment.apiUrl}/users/delete-profile-picture`,
  MY_DATA_SET                       : `${environment.apiUrl}/users/list-manageset`,
  ADD_DATA_SET                      : `${environment.apiUrl}/users/create-manageset`,
  EDIT_DATA_SET                     : `${environment.apiUrl}/users/edit-manageset`,
  GET_SECTION_FIELDS                : `${environment.apiUrl}/users/field-list`,
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
