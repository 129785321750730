// auth.service.ts

import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn(): boolean {
    return localStorage.getItem('authToken') ? true : false;
  }

  logout()
  {
    // localStorage.removeItem('authToken');
    // localStorage.removeItem('USER_DATA');
    localStorage.clear();
  }

  saveUser(user:any): void {
    localStorage.removeItem('USER_DATA');
    localStorage.setItem('USER_DATA', JSON.stringify(user));
  }

  getUser()
  {
    let currentUserData = localStorage.getItem('USER_DATA');
    
    return JSON.parse(currentUserData || '{}');
  }

  storeToken(token:any):void
  {
    localStorage.setItem('authToken', token);
  }
  storeIsPaid(planData:any)
  {
    localStorage.setItem('active_plan', planData);
  }
  storeIsFree(planData:any)
  {
    localStorage.setItem('user_plan_free', planData);
  }
  getIsPaid()
  {  
    var active_plan = localStorage.getItem('active_plan');
    var user_plan_free = localStorage.getItem('user_plan_free');
    if(active_plan == '2'){//premium
      return true;
    }else{//checking user is free user or expired
      if(user_plan_free == '1'){//free user
        return true;
      }else{//expired
        return false;
      }
    }
    // return localStorage.getItem('active_plan') === '2' ? true : false;

  }

}