// modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { TabsModule } from 'ngx-tabset';
import { DatepickerModule } from 'ng2-datepicker';

// components
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AccordionModule } from "ngx-accordion";
import { ColorPickerModule } from 'ngx-color-picker';

import { LogoutComponent } from './logout/logout.component';
import { ToastrComponent } from './toastr/toastr.component';
import { ErrorComponent } from './error/error.component';


import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PointerAutoFocusDirective } from './pointer-auto-focus.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GoogleAnalyticsComponent } from './google-analytics/google-analytics.component';


@NgModule({ 
  declarations: [AppComponent, LogoutComponent, ToastrComponent, ErrorComponent, PointerAutoFocusDirective, GoogleAnalyticsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    OrderModule,
    DatepickerModule,
    BrowserAnimationsModule,
    AccordionModule,
    ColorPickerModule,
    TabsModule.forRoot(),
    HttpClientModule,
    DragDropModule,
    MatDatepickerModule,
    CarouselModule,
    NgxIntlTelInputModule
  ],

  schemas: [NO_ERRORS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
