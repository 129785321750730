import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/service/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private Auth : AuthService , private route : Router) { }

  ngOnInit(): void {

    this.Auth.logout();
    this.route.navigate(['/']); 

  }

}
